<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>

          <v-toolbar color="grey darken-2" flat>
            <v-spacer/>
            <v-toolbar-title class="text-white text-center">{{$t('settings.lang_paperlessInvoice')}}</v-toolbar-title>
            <v-spacer/>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-switch :label="$t('settings.lang_enablePaperlessInvoice')" v-model="enablePaperlessInvoice" false-value="0" true-value="1"/>
                <v-switch :label="$t('settings.lang_displayPaperlessInvoiceQRInCustomerDisplay')" v-model="displayPaperlessInvoiceQr" false-value="0" true-value="1"/>
                <v-switch :label="$t('settings.lang_PaperlessInvoiceSmallInvoice')" v-model="printSmallReceipt" false-value="0" true-value="1"/>
                <v-switch :label="$t('settings.lang_paperlessInvoiceDisablePrint')" v-model="disableReceiptPrint" false-value="0" true-value="1"/>
                <v-select
                    v-model="selectedCashierIDs"
                    dense
                    :items="filteredCashierIDs" item-text="text" item-value="value"
                    :label="$t('generic.lang_cashierID')"
                    multiple
                    outlined
                ></v-select>
                <v-text-field :label="$t('generic.lang_message')" v-model="paperlessInvoiceMSG" dense outlined/>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="ma-0"/>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" :disabled="loading" :loading="loading" @click="saveSettings">
              {{ $t('generic.lang_save') }}
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>


import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "PaperlessInvoiceSettings",
  data() {
    return {
      loading: false,
      enablePaperlessInvoice: 0,
      displayPaperlessInvoiceQr: 0,
      printSmallReceipt: 0,
      disableReceiptPrint: 0,
      paperlessInvoiceMSG: null,
      selectedCashierIDs:[],
      cashierIDs:[],
    }
  },
  watch:{
    printSmallReceipt(val){
      this.disableReceiptPrint = val==='1'?0:'1';
    },
    disableReceiptPrint(val){
      this.printSmallReceipt = val==='1'?0:'1';
    },
  },
  mounted() {
    this.getSettings();
    this.getCashiers();
  },
  computed:{
    filteredCashierIDs() {
      return this.cashierIDs.map((cashierData) => {
        return {
          value: cashierData[0],
          text: "Kasse "+ cashierData[0] +((cashierData[1]&&cashierData[1].length>0)?(' ( '+ cashierData[1].replace(',',' ')+' ) '):''),
        };
      });
    }
  },
  methods: {
    getCashiers() {
      this.loading = true;

      this.axios.post(ENDPOINTS.GENERIC.CASHIERIDS.GET).then((res) => {
        this.cashierIDs = res.data.cashierIDs;
      }).finally(() => this.loading = false);
    },
    getSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.MODULESETTINGS.PAPERLESSINVOICE.GET).then((res) => {
        if (res.status===200) {
          this.enablePaperlessInvoice = res.data.formfillData.textFields.enablePaperlessInvoice;
          this.displayPaperlessInvoiceQr = res.data.formfillData.textFields.displayPaperlessInvoiceQr;
          this.printSmallReceipt = res.data.formfillData.textFields.printSmallReceipt;
          this.disableReceiptPrint = res.data.formfillData.textFields.disableReceiptPrint;
          this.paperlessInvoiceMSG = res.data.formfillData.textFields.paperlessInvoiceMSG;
          this.selectedCashierIDs = res.data.formfillData.textFields.paperlessCashierIDs;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    saveSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.MODULESETTINGS.PAPERLESSINVOICE.UPDATE, {
        enablePaperlessInvoice: this.enablePaperlessInvoice,
        displayPaperlessInvoiceQr: this.displayPaperlessInvoiceQr,
        printSmallReceipt: this.printSmallReceipt,
        disableReceiptPrint: this.disableReceiptPrint,
        paperlessInvoiceMSG: this.paperlessInvoiceMSG,
        paperlessCashierIDs: this.selectedCashierIDs,
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).finally(() => {
        this.loading = false;
      });
    },
  }

}
</script>